body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #fb8404;
  --button-hover-color: #b14e07;
  --secondary-color: #222C5D;
  --white-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

html {
  font-family: "Roboto", "Exo 2", "Barlow Semi Condensed", "Lato", "Montserrat", sans-serif;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background: #000;
  @media (max-width: 960px) {
    font-size: 40%;
  }
}

body {
  color: var(---white-color); 
  background-color: linear-gradient('#222C5D', '#121933');
}
